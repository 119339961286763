:root {
    --orange:#FF5100;
    --secondary_blue: #000066;
    --secondary_light: #4c557d;
    --cement: #575766;
}

.bg-orange {
    background: var(--orange);
}

.border-orange {
    border-color: var(--orange);
}

.text-orange {
    color: var(--orange);
}
.text-secondary {
    color: var(--secondary_blue)
}
.text-secondary-light {
    color: var(--secondary_light)
}
.text-cement {
    color: var(--cement)
}
.border-dotted {
    border-style: dotted;
}

.border-b-6 {
    border-bottom-width: 6px;
}

.border-l-6 {
    border-left-width: 6px;
}

.drawer {
    max-width: 600px;
    min-width: 500px;
    width: 100%;
}