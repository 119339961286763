.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #FF2D01;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  position: absolute;
  display: flex;
  /* height: 100%; */
  margin: 0 auto;
  display: block;
  z-index: 2 !important;
}
.container {
  position: fixed;
  max-width: 100%;
    background: grey;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1 !important;
    opacity: 0.6;
    
}
.html, .body {
  overflow: hidden
}

.containerLoader{
  position: fixed;
    background: transparent;
    height: 100%;
    width: 100%;
    z-index: 30000;
    word-wrap: break-word;
    left: 50% !important;
    top: 40%;
}