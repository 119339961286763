.point_firebase {
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: 0;
}

.contaiener_point_firebase {
  position: relative;
}